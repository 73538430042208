import React from "react";
import Card from "react-bootstrap/Card";
import { MyRegisterationlist } from "../../../assets/data/myregistrationcards.js";

import clockicon from "../../../assets/images/myregisteration/clock-icon.png";

import { Link, navigate } from "gatsby";

import Breadcrumbsbanner from "../breadcumbbanner/breadcumbsbanner";
import KanhaRunService from "../../services/kanha.service.js";
import { useEffect } from "react";
import { useState } from "react";
import { paymentStatus } from "../../../assets/data/commonData.js";
import View from "../../../assets/images/view.svg";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import Loading from "../loading.jsx";
import { lStorage } from "../../utils/storage.js";

export default function MyRegisterationlistpage() {
  const registerationname = "New Registration";
  const kanhaApi = new KanhaRunService();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState("/basicformpage");

  const logOut = () => {
    lStorage.remove("userInfo");
    lStorage.remove("verification_id");
    lStorage.remove("list");
    lStorage.remove("globalRun");
    lStorage.remove("appliedCouponCode");
    navigate("/");
  };

  const getRegisterList = () => {
    setLoading(true);
    kanhaApi
      .getorderlist()
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            if(response?.data?.data?.length === 0){
              navigate("/racelocations");
            }else{
              setLoading(false);
              setList(response.data.data);
            }
          } else {
            setLoading(false);
            // console.log("error");
          }
          //  navigate("/basicformpage");
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
        setLoading(false);
        logOut();
      });
  };
  const detailsPage = (data) => {
    const editData = data;
    lStorage.set("detailsList", data);
    navigate("/participantDetails", { state: { editData } });
  };
  useEffect(() => {
    const selectLoc = lStorage.get("selectedLocation");
    if (selectLoc) {
      setRedirectUrl("/basicformpage");
    } else {
      setRedirectUrl("/racelocations");
    }
    getRegisterList();
  }, []);

  const proceedToPay = (paymentData) => {
    setLoading(true);
    const payLoad = {
      pnr_no: paymentData.pnr_no,
    };
    kanhaApi
      .retrypayment(payLoad)
      .then((response) => {
        setLoading(false);

        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            window.open(response.data.message.paymentUrl, "_parent");
          } else {
            // console.log("error");
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    const timer = setInterval(
      () => getRegisterList(),
      60000
    );
    return () => clearInterval(timer);
  });

  return (
    <>
      <Breadcrumbsbanner />
      <div
        className="myregistertionlist-wrapper inner-pages p-4"
        style={{ marginBottom: "150px" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="p-2">
              {/* <Button
                className=" btn btn-primary btn-block"
                style={{ float: "right" }}
                onClick={logOut}
              >
                Logout{" "}
                <img
                  className="img-fluid"
                  src={logOutimg}
                  width="15px"
                  alt="Clock logo"
                />
              </Button> */}
            </div>
            <div className="col-md-12">
              <div className="myregisteration-wrapper-box">
                <h3> My Registration </h3>

                <Link
                  className="btn btn-orange btn-right-order lower-case  "
                  to={redirectUrl}
                 
                >
                  {registerationname}
            </Link> 
              </div>
            </div>
          </div>
          <div className="row">
            {list &&
              list.map((data) => {
                return (
                  <div
                    className="col-md-4 col-sm-6"
                    // onClick={() => {
                    //   detailsPage(data);
                    // }}
                  >
                    <div className="card cards-list-wrapper hand">
                      <Card>
                        <Card.Body>
                          <ul>
                            <li>
                              <Card.Link href="#">
                                <b> {data.pnr_no}</b>
                              </Card.Link>
                            </li>
                            <li>
                              <Card.Link href="#">
                                <span
                                  style={{
                                    color:
                                      data?.payment_status ===
                                        "PAYMENT_PENDING" ||
                                      data?.payment_status === "ORDER_FAILED"
                                        ? "#DB463A"
                                        : "#0CAE10",
                                  }}
                                >
                                  <b> {data.payment_status === "ORDER_FAILED" && data.payment_failed_reason !== null && data.payment_failed_reason =='PG_LATE_AUTH_TIMEOUT' && (
                           <b>**</b>
                           )}
                           {paymentStatus(data.payment_status)}</b>
                                </span>
                              </Card.Link>
                            </li>
                            <li>
                              <Card.Link href="#">Participants</Card.Link>
                            </li>
                            <li>
                              <b>
                                <Card.Link href="#">
                                  {data.participant.length}
                                </Card.Link>
                              </b>
                            </li>
                            <li>
                              <Card.Link href="#">Total Amount</Card.Link>
                            </li>
                            <li>
                              <b>
                                <Card.Link href="#">{data.amount} ₹</Card.Link>
                              </b>
                            </li>

                            <li>
                              <Card.Link href="#">Date</Card.Link>
                            </li>
                            <li>
                              <b>
                                <Card.Link href="#">{moment(data.created_at).format(
                                    "DD MMM h:mm a"
                                  )}</Card.Link>
                              </b>
                            </li>
                            <li className="clock-custom-icon">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexWrap:'wrap',                                  
                                }}
                              >
                                <div style={{ paddingBottom:"10px"}}>
                                  <Button
                                    className=" btn btn-primary btn-block"
                                    style={{ float: "right",width:"127px", }}
                                    onClick={() => {
                                      detailsPage(data);
                                    }}
                                  >
                                    View Details
                                  </Button>
                                </div>
                                {/* <div style={{ paddingTop:"10px", color:"#0CAE10"}}>
                                  {data.payment_status === "ORDER_CONFIRMED" && (
                                    <div>Certificate Available</div>
                                  )}
                                </div> */}
                                <div>
                                {/*  {data.retrypayment === 1 && (
                                    <div>
                                      <Button
                                        className="btn btn-warning"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          proceedToPay(data);
                                        }}
                                      >
                                        Retry Payment
                                      </Button>
                                    </div>
                                      )}*/}
                                  {/* {data.payment_status ===
                                    "PAYMENT_PENDING" && (
                                    <div>
                                      <Button
                                        className="btn btn-warning"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          proceedToPay(data);
                                        }}
                                      >
                                        Retry Payment
                                      </Button>
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            </li>
                          </ul>
                           <div>
                            {data.payment_status === "ORDER_FAILED" && data.payment_failed_reason !== null && data.payment_failed_reason =="PG_LATE_AUTH_TIMEOUT" && (
                            <span
                            style={{
                              color:
                                data?.payment_status ===
                                  "PAYMENT_PENDING" ||
                                data?.payment_status === "ORDER_FAILED"
                                  ? "#DB463A"
                                  : "#0CAE10",
                            }}
                          >
                            <b>**</b>If payment has failed and money has been debited from your account, it may take up to 5-7 business days for your bank to credit the refund amount to your account
                          </span>
                            )}
                          </div> 
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                );
              })}
            {loading === false && list.length === 0 && (
              <p>
                <b>No Records Found - Please click on New Registration</b>
              </p>
            )}
          </div>
        </div>
        {loading === true && (
          <Modal id="loading" show={loading} style={{ marginTop: "50vh" }}>
            <Loading />
          </Modal>
        )}
      </div>
    </>
  );
}
